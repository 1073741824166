import { Route, Routes } from "react-router-dom";
import Inquiry from "./pages/Inquiry";
import Home from "./pages/Home";
import Header from "./pages/Layout/Header";
import Emails from "./pages/Emails";

function App() {
  return (
    <div className="App bg-white" id="app-root">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/inquiry" element={<Inquiry />} />
        <Route path="emails" element={<Emails />} />
      </Routes>
    </div>
  );
}

export default App;
