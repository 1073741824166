import React from "react";
import { motion } from "framer-motion";

const Button = ({ className, children, onClick, isDisable = false }) => {
  return (
    <motion.button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      className={`transition duration-500 ease-in-out font-bold cursor-pointer ${className} ${
        isDisable ? "opacity-50" : ""
      }`}
      disabled={isDisable}
      onClick={onClick}
    >
      {children}
    </motion.button>
  );
};

export default Button;
