import React, { useEffect, useState } from "react";
import { API_URL, INQUIRY_PASSWORD } from "../../config";

const Emails = () => {
  const [data, setData] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const emailTable = {
    headers: [
      { label: "Email", key: "email" },
      { label: "Date", key: "createdAt" },
    ],
  };

  // This useEffect handles authentication and data fetching
  useEffect(() => {
    // Prompt the user for password when component mounts
    const password = prompt("Enter the password to view the inquiry:");
    const correctPassword = INQUIRY_PASSWORD; // Replace with your actual password

    if (password === correctPassword) {
      fetchInquiryDetails(); // Fetch the data only after authentication is successful
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      alert("Incorrect password! You do not have access.");
    }
  }, []);

  const fetchInquiryDetails = async () => {
    try {
      const response = await fetch(`${API_URL}email`);
      const data = await response.json();
      setData(data.payload);
    } catch (error) {
      console.error("Error fetching inquiry details:", error);
    }
  };

  return (
    <div className="container mx-auto px-4 sm:px-8">
      {isAuthenticated ? (
        <div className="py-8">
          <div>
            <h2 className="text-2xl font-semibold leading-tight text-purple">
              Emails
            </h2>
          </div>

          {data.length === 0 ? (
            <div className="text-center py-8">
              <p className="text-lg text-gray-600">No emails found.</p>
            </div>
          ) : (
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      {emailTable.headers.map((header, idx) => (
                        <th
                          key={idx}
                          className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                        >
                          {header.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {emailTable?.headers?.map((header, colIndex) => (
                          <td
                            key={colIndex}
                            className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                          >
                            <p className="text-gray-900 whitespace-no-wrap">
                              {header?.key === "createdAt"
                                ? new Date(
                                    row[header?.key]
                                  ).toLocaleDateString()
                                : row[header?.key]}
                            </p>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="text-center py-8">
          <p className="text-xl text-red-600">
            Access Denied! Incorrect Password.
          </p>
        </div>
      )}
    </div>
  );
};

export default Emails;
