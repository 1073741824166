import React from "react";

const HeadsinJourney = () => {
  const service = [
    {
      startingCharacter: "H",
      title: "ire Value",
      description:
        "85% of recruiters prioritize impact—HeadsIn ensures every hire drives value and purpose. Empower organizations to build teams that truly make a difference.",
      image: "hire-value.svg",
      width: 33,
      height: 40,
      className: "top-[80px]",
    },
    {
      startingCharacter: "E",
      title: "mphasize Transparency",
      description:
        "HeadsIn’s AI-powered platform ensures every candidate is engaged and excited about the opportunity. Drive higher acceptance rates and reduce time-to-fill.",
      image: "emphasize.svg",
      width: 40,
      height: 40,
      className: "top-[150px]",
    },
    {
      startingCharacter: "A",
      title: "i Driven",
      description:
        "Filter candidates 2x faster HeadsIn’s AI-powered assessments streamline hiring, saving time and effort.Harness the power of technology to redefine how you find top talent.",
      image: "ai-driven.svg",
      width: 40,
      height: 40,
      className: "top-[225px]",
    },
    {
      startingCharacter: "D",
      title: "stinct Traceability",
      description:
        "Never miss a step—trace applications end-to-end, reducing decision delays by 40%. HeadsIn bridges communication gaps, ensuring clarity for both sides of the hiring process.",
      image: "distinct-traceability.svg",
      width: 40,
      height: 25,
      className: "top-[300px]",
    },
    {
      startingCharacter: "S",
      title: "eamless Experience",
      description:
        "Cut hiring complexities—HeadsIn’s integrated tools deliver a 50% smoother journey for candidates and recruiters alike. Effortlessly navigate from job posting to onboarding with absolute ease",
      image: "seamless-experience.svg",
      width: 40,
      height: 31,
      className: "top-[370px]",
    },
    {
      startingCharacter: "I",
      title: "nduct the Perfect Fit",
      description:
        "Pre-assessments reduce mismatches by 70%, saving 30% on recruitment costs while identifying the ideal talent quickly.HeadsIn aligns the right talent with the right role, faster and smarter.",
      image: "induct-the-perfect-fit.svg",
      width: 40,
      height: 33,
      className: "top-[440px]",
    },
    {
      startingCharacter: "N",
      title: "o Fake, No Wait",
      description:
        "Eliminate fake listings and cut hiring delays in half—HeadsIn ensures authenticity and efficiency at each step.Your time matters; we value it by delivering only real, ready opportunities.",
      image: "no-fake-no-wait.svg",
      width: 40,
      height: 33,
      className: "top-[520px]",
    },
  ];

  return (
    <section className="container px-[40px] my-[28px]">
      <div>
        {service?.map((step, i) => {
          return (
            <div
              key={i}
              className={`sticky ${step.className} mb-2 drop-shadow-lg p-[24px] rounded-lg border-purple 
                 border-[1.5px] border-solid bg-white`}
            >
              <div>
                <h3 className="flex items-end text-[19px] text-[#9882ac]">
                  <span className="text-[40px] text-purple font-extrabold leading-[43px]">
                    {step.startingCharacter}
                  </span>{" "}
                  <span>{step.title}</span>
                </h3>
              </div>

              <div>
                <p className="text-[14px] text-black mt-3 font-medium">
                  {step.description}
                </p>
              </div>
              <div className="pt-4 md:pt-0">
                <img
                  alt=""
                  loading="lazy"
                  src={`/static/images/journey/${step.image}`}
                  width={step.width}
                  height={step.height}
                  className=""
                />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default HeadsinJourney;
