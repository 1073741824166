import React from "react";
import Modal from "../ui/Modal";

const SuccessWaitListModal = ({ isOpen, setIsOpen }) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <img
        src={"/static/images/success.gif"}
        width={250}
        height={153}
        className="m-auto w-[250px] h-[153px] object-cover"
        alt="success"
      />
      <p className="text-[20px] text-[#4B465C] leading-[36px] font-mulish text-center font-semibold mt-4">
        Stay tuned as we reshape the future of hiring together! —exciting
        updates are coming your way!
      </p>
    </Modal>
  );
};

export default SuccessWaitListModal;
