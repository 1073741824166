import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="bg-white px-[16px] sm:px-[120px] sm:py-[28px] py-[18px] flex justify-between items-center sticky top-0 drop-shadow-md z-[2]">
      <Link to={"/"}>
        <img
          src={"/static/images/logo.svg"}
          width={138}
          height={22}
          alt="Headsin Logo"
          className="sm:w-[138px] w-[110px] sm:h-[22px] h-[18px] cursor-pointer"
        />
      </Link>
      <div>
        <a
          href={"#join-list"}
          className="bg-purple transition duration-500 ease-in-out border hover:border-purple hover:border hover:text-purple hover:bg-transparent text-white sm:text-[16px] text-[12px] leading-[18px] sm:p-[14px] p-[8px] rounded-md font-bold"
        >
          Join waitlist
        </a>
      </div>
    </header>
  );
};

export default Header;
