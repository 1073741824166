import Button from "../ui/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from "../ui/Modal";
import { useState } from "react";
import Toast from "../ui/Toast";
import { Link } from "react-router-dom";
import { API_URL } from "../../config";

const Footer = () => {
  const [isContacted, setIsContacted] = useState(false);
  const [isFailedContacted, setIsFailedContacted] = useState(false);
  const [message, setMessage] = useState("");
  const footerContent = {
    contact: {
      title: "Contact Us",
      highlight: "Us",
      formFields: [
        {
          type: "text",
          id: "name",
          name: "name",
          label: "Name",
          placeHolder: "Enter Name",
        },
        {
          type: "number",
          id: "mobile",
          name: "mobile",
          label: "Mobile",
          placeHolder: "Enter Mobile",
        },
        {
          type: "email",
          id: "email",
          name: "email",
          label: "Email",
          placeHolder: "Enter Email",
        },
        {
          type: "textarea",
          id: "message",
          name: "message",
          label: "Your Message",
          placeHolder: "Enter Message",
          rows: 5,
        },
      ],
      button: {
        label: "Send",
        className:
          "border border-white rounded-[8px] w-[128px] h-[44px] float-end mt-5 hover:bg-white hover:text-black",
      },
    },
    footerBottom: {
      logo: {
        src: "/static/images/white-logo.png",
        width: 207,
        height: 34,
        alt: "Headsin Logo",
        text: "© 2024 Headsin | All rights reserved",
      },
      socialMedia: [
        {
          href: "https://www.linkedin.com/company/headsinco/",
          src: "/static/images/social-media/linkedin.png",
          width: 23,
          height: 23,
          alt: "linkedin",
        },
        {
          href: "https://www.instagram.com/headsin.co/profilecard/?igsh=MTEyd3pyYzhhYzMzMA==",
          src: "/static/images/social-media/instagram.png",
          width: 23,
          height: 23,
          alt: "instagram",
        },
        {
          href: "mailto:headsin.get@gmail.com",
          src: "/static/images/social-media/mail.png",
          width: 26,
          height: 26,
          alt: "mail",
        },
      ],
    },
  };

  const { contact, footerBottom } = footerContent;

  const formik = useFormik({
    initialValues: contact.formFields.reduce((values, field) => {
      values[field.name] = "";
      return values;
    }, {}),
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      mobile: Yup.string()
        .matches(/^[6-9]\d{9}$/, "Invalid mobile number")
        .required("Mobile is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: async (values) => {
      values.mobile = values.mobile.toString();
      try {
        const response = await fetch(`${API_URL}contact`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || "Failed to send message");
        }
        setIsContacted(true);
        formik.resetForm(); // Reset form after submission
      } catch (error) {
        setIsFailedContacted(true);
        setMessage(error.message);
      }
    },
  });

  return (
    <footer className="sm:py-[60px] py-5 sm:rounded-tl-[60px] sm:rounded-tr-[60px] rounded-tl-[30px] rounded-tr-[30px] text-white sm:px-[52px] px-5">
      <div className="w-full max-w-[954px] m-auto sm:pb-[72px] pb-6 footer-container">
        <div className="grid sm:grid-cols-2 grid-cols-1">
          <div className="sm:mb-0 mb-6 text-start">
            <h2 className="sm:text-[40px] text-[28px] font-extrabold">
              {contact.title.split(" ")[0]}{" "}
              <span className="text-pink">{contact.highlight}</span>
            </h2>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5 text-start">
              {contact.formFields.map((field, index) => (
                <div
                  key={index}
                  className={
                    field.type === "textarea" || field.name === "email"
                      ? "sm:col-span-2 col-span-1"
                      : ""
                  }
                >
                  <label htmlFor={field.id} className="block">
                    {field.label}
                  </label>
                  {field.type === "textarea" ? (
                    <textarea
                      id={field.id}
                      name={field.name}
                      rows={field.rows}
                      value={formik.values[field.name]}
                      placeholder={field.placeHolder}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="bg-[#DBDADE] rounded-[8px] py-[9px] px-4 w-full text-[#4B465C] font-mulish"
                    />
                  ) : (
                    <input
                      type={field.type}
                      id={field.id}
                      name={field.name}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      placeholder={field.placeHolder}
                      onBlur={formik.handleBlur}
                      className="bg-[#DBDADE] rounded-[8px] py-[9px] px-4 w-full text-[#4B465C] font-mulish"
                    />
                  )}
                  {formik.touched[field.name] && formik.errors[field.name] && (
                    <div className="text-red-500 text-sm mt-1">
                      {formik.errors[field.name]}
                    </div>
                  )}
                </div>
              ))}
            </div>

            <Button
              type="submit"
              disabled={formik.isSubmitting}
              className={contact.button.className}
            >
              {contact.button.label}
            </Button>
          </form>
        </div>
      </div>

      <hr className="sm:pb-[72px] pb-6" />

      <div className="flex justify-between items-center">
        <div>
          <img
            src={footerBottom.logo.src}
            width={footerBottom.logo.width}
            height={footerBottom.logo.height}
            alt={footerBottom.logo.alt}
            className="sm:w-[207px] sm:h-[34px] w-[87px] h-[14px]"
          />
          <p className="mt-[12px] text-[10px] sm:text-[16px]">
            {footerBottom.logo.text}
          </p>
        </div>

        <ul className="flex gap-4 items-center">
          {footerBottom.socialMedia.map((social, index) => (
            <li key={index}>
              <Link to={social.href} target="_blank">
                <img
                  src={social.src}
                  width={social.width}
                  height={social.height}
                  alt={social.alt}
                />
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <Toast
        open={isFailedContacted}
        setOpen={setIsFailedContacted}
        message={message}
      />

      <Modal isOpen={isContacted} setIsOpen={setIsContacted}>
        <img
          src={"/static/images/success.gif"}
          width={250}
          height={153}
          className="m-auto w-[250px] h-[153px] object-cover"
          alt="success"
        />
        <p className="text-[20px] text-[#4B465C] leading-[36px] font-mulish text-center font-semibold mt-4">
          Thank you for contacting us. We will get back to you soon.
        </p>
      </Modal>
    </footer>
  );
};

export default Footer;
