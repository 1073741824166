import React from "react";

const OurMissionVision = ({ isMobileDevice }) => {
  const missionVision = [
    {
      title: "Mission",
      description:
        "To empower millions with Al-driven hiring, transparent processes, and tailored solutions to eliminate inefficiencies, enhance transparency, and thrive in a growing economy of India.",
      image: "/static/images/mission-layer.png",
    },
    {
      title: "Vision",
      description:
        "Headsin envisions to Redefine the recruitment and job ecosystem for India's youth bridging the gap between right talent and opportunity fueling economic growth and lasting impact. ",
      image: "/static/images/vision-layer.png",
    },
  ];

  return (
    <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-[30px] gap-[18px] max-w-[1260px] m-auto mt-[26px] sm:px-[95px] px-[36px]">
      {missionVision.map((item, index) => (
        <div
          key={index}
          className={`sm:text-inherit text-center ${
            index === 0 ? "sm:border-r-[1px] sm:border-purple" : ""
          } `}
        >
          <div className="block sm:flex items-center sm:text-inherit text-center sm:gap-[30px] gap-[9px]">
            <img
              src={item.image}
              width={70}
              height={70}
              alt={item.title}
              className="sm:w-[70px] sm:h-[70px] w-[21px] h-[21px] sm:mx-0 mx-auto"
            />
            <h2 className="text-pink font-extrabold sm:text-[28px] text-[14px]">
              {item.title}
            </h2>
          </div>
          <p className="sm:text-[20px] text-[12px] sm:mt-[30px] mt-1 text-purple sm:text-start text-center font-medium">
            {item.description}
          </p>
        </div>
      ))}
      {/* <div className="flex items-start gap-[30px] border-r-[1px] border-purple">
        <img
          src={"/static/images/mission-layer.png"}
          width={70}
          height={70}
          alt="mission-layer"
          className="sm:w-[70px] sm:h-[70px] w-[21px] h-[21px]"
          
        />
        <p className="text-[28px]">
          <span className="text-pink font-extrabold">Mission</span> to
          streamline job searching and hiring in India, providing a transparent
          ecosystem.
        </p>
      </div>

      <div className="flex items-start gap-[30px]">
        <img
          src={"/static/images/vision-layer.png"}
          width={70}
          height={70}
          alt="mission-layer"
          className="w-[70px] h-[70px]"
          
        />
        <p className="text-[28px]">
          <span className="text-pink font-extrabold">Vision</span> of a faster,
          efficient job ecosystem that serves both job seekers and employers.
        </p>
      </div> */}
    </div>
  );
};

export default OurMissionVision;
