import React, { useEffect } from "react";
import { Box, Modal as MuiModal } from "@mui/material";
import useWindowSize from "../../hooks/useWindowSize";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 496,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
};

const Modal = ({ isOpen, setIsOpen, children }) => {
  const handleClose = () => setIsOpen(false);
  const { width } = useWindowSize();

  useEffect(() => {
    const getId = document.getElementById("app-root");
    // Add or remove the blur class to the body when isOpen changes
    if (isOpen) {
      getId.classList.add("blur");
    } else {
      getId.classList.remove("blur");
    }

    // Cleanup function to remove the class if component unmounts
    return () => {
      getId.classList.remove("blur");
    };
  }, [isOpen]);

  return (
    <MuiModal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
          width: "100%",
          maxWidth: width > 512 ? "496px" : "360px",
        }}
        className="p-[40px] focus-visible:outline-none"
      >
        <img
          src={"/static/images/close-icon.png"}
          width={24}
          height={24}
          alt="close-icon"
          className="absolute top-3 right-3 cursor-pointer"
          onClick={handleClose}
        />
        <div>{children}</div>
      </Box>
    </MuiModal>
  );
};

export default Modal;
