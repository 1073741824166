import { CircularProgress } from "@mui/material";
import { div } from "motion/react-client";
import React from "react";

const Loader = () => {
  return (
    <div className="text-center">
      {/* // <img
    //   src={"/static/images/loader.gif"}
    //   alt="rocket"
    //   className="m-auto"
    // /> */}
      <CircularProgress size="3rem" color="secondary"/>
    </div>
  );
};

export default Loader;
