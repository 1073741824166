import React, { useEffect, useState } from "react";
import withLazyLoad from "../../hoc/withLazyLoad";
import Footer from "../Layout/Footer";
import WaitListModal from "./WaitListModal";
import SuccessWaitListModal from "./SuccessWaitListModal";
import Toast from "../ui/Toast";
import OurMissionVision from "./OurMissionVision";
import HeadsinJourney from "./HeadsinJourney";
import Button from "../ui/Button";
import { Link } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import { API_URL } from "../../config";
import { motion } from "framer-motion";

// Lazy import the BridgingTalentJobs component
const DiscoverAssess = React.lazy(() => import("./DiscoverAssess"));
const BridgingTalentJobs = React.lazy(() => import("./BridgingTalentJobs"));

// // Wrap it with the HOC
// const DiscoverAssess = withLazyLoad(LazyDiscoverAssess);
// const BridgingTalentJobs = withLazyLoad(LazyBridgingTalentJobs);

const Home = () => {
  const [isOpenWaitListModal, setIsOpenWaitListModal] = useState(false);
  const [isSuccessWaitList, setIsSuccessWaitList] = useState(false);
  const [isFailedWaitList, setIsFailedWaitList] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  const { width } = useWindowSize();

  useEffect(() => {
    setIsMobileDevice(width < 512);
  }, [width]);

  const features = [
    {
      title: "Pre-Assessed Candidates",
      description:
        "Recruiters can skip manual screenings with candidates that are verified and evaluated for credibility.",
      icon: {
        src: "/static/images/pre-assessed.gif",
        width: 44,
        height: 44,
        alt: "pre-assessed",
      },
      arrow: {
        src: "/static/images/why-choose-arrow-1.png",
        width: 232,
        height: 48,
        alt: "why-choose-arrow-1",
        class: "absolute -top-[10px] -right-[120px]",
      },
      shadow: true,
    },
    {
      title: "Real-Time Traceability",
      description:
        "Track the status of every job application or candidate interaction—no more uncertainty.",
      icon: {
        src: "/static/images/traceability.gif",
        width: 44,
        height: 44,
        alt: "traceability",
      },
      arrow: {
        src: "/static/images/why-choose-arrow-2.png",
        width: 221,
        height: 48,
        alt: "why-choose-arrow-1",
        class: "absolute -bottom-[10px] -right-[120px]",
      },
    },
    {
      title: "Two-Way Transparency",
      description:
        "For both job seekers and recruiters, ensuring smooth communication and better decision-making.",
      icon: {
        src: "/static/images/handshake.gif",
        width: 44,
        height: 44,
        alt: "handshake",
      },
      arrow: {
        src: "/static/images/why-choose-arrow-3.png",
        width: 221,
        height: 48,
        alt: "why-choose-arrow-1",
        class: "absolute -top-[10px] -right-[120px]",
      },
    },
    {
      title: "Activity Status Flags",
      description:
        "Know if a candidate is actively looking or already engaged elsewhere, saving time on unnecessary follow-ups.",
      icon: {
        src: "/static/images/post.gif",
        width: 44,
        height: 44,
        alt: "post",
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsOpenWaitListModal(true);
  };

  const waitListModalSubmit = async (waitListType) => {
    const payload = {
      userType: waitListType === "job-seeker" ? "Job Seeker" : "Employer",
      email,
    };
    try {
      const response = await fetch(`${API_URL}email/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        setIsOpenWaitListModal(false);
        setIsSuccessWaitList(true);
        setEmail("");
      } else {
        const data = await response.json();
        setMessage(data.message);
        setIsFailedWaitList(true);
        setIsOpenWaitListModal(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const talents = [
    {
      title: "Got talent?",
      description: "Why job seekers love us",
      details: [
        {
          icon: "briefcase.svg",
          text: "Ai-tailored job matches",
        },
        {
          icon: "repeat.svg",
          text: "Live application updates",
        },
        {
          icon: "mail.svg",
          text: "Feedback on Rejections",
        },
        {
          icon: "file-text.svg",
          text: "AI-Powered Resume Builder",
        },
      ],
    },
    {
      title: "Need talent?",
      description: "Why recruiters love us",
      details: [
        {
          icon: "user-check.svg",
          text: "Pre-assessed candidates",
        },
        {
          icon: "message.svg",
          text: "In-App Communication",
        },
        {
          icon: "stars.svg",
          text: " Trusted Profiles",
        },
        {
          icon: "user-circle.svg",
          text: "Real-Time Candidate Status",
        },
      ],
    },
  ];

  return (
    <div className="border-t-2 mb-[60px]">
      {/* Hero section */}
      <section
        className="text-purple hero-section relative sm:mt-[50px] mt-[32px]"
        // style={{
        //   backgroundImage: "url('/static/images/hero-gradient.png')",
        //   backgroundSize: "1000px",
        //   backgroundPosition: "center -29px",
        // }}
      >
        <div className="">
          <div className="text-center mb-5">
            <Button
              className={
                "bg-gradient-3color text-white py-2 sm:text-[16px] text-[10px] sm:py-[10px] sm:px-[24px] font-bold px-3 rounded-full mb-5"
              }
            >
              Launching Soon...
            </Button>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.5 }}
            >
              <div className="sm:text-[83px] text-[34px] leading-[91px] font-extrabold flex justify-center items-center">
                Find Your{" "}
                <div className="relative z-[1]">
                  <img
                    src={"/static/images/round-pink.png"}
                    width={198}
                    height={118}
                    alt="round-pink"
                    className="sm:w-[198px] sm:h-[118px] w-[79px] h-[47px]"
                  />
                  <span className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
                    Fit,{" "}
                  </span>
                </div>
                Fast{" "}
              </div>

              <p className="text-purple sm:text-[28px] text-[12px] sm:leading-[44px] sm:max-w-[44%] m-auto w-full sm:mt-[10px] mt-[12px] font-extralight font-actor">
                Redefining the way India hires and get hired
              </p>
            </motion.div>
          </div>

          <img
            src={
              width > 512
                ? "/static/images/hero-section.gif"
                : "/static/images/hero-section-mobile.gif"
            }
            width={1440}
            height={400}
            alt="hero-section"
            className="m-auto"
          />
        </div>

        <OurMissionVision isMobileDevice={isMobileDevice} />
      </section>

      <section
        className={`bg-purple sm:pb-[60px] sm:px-[60px] px-[20px] text-white sm:mt-[60px] mt-[28px] sm:pt-[120px] ${
          isMobileDevice ? "talent-section" : "wave"
        }`}
      >
        {width > 512 ? (
          <>
            <h1 className="sm:text-[32px] text-[16px] font-light mb-[50px] text-center">
              Why Choose <span className="font-bold">HeadsIn</span>{" "}
            </h1>

            <div className="flex gap-5 justify-center">
              {/* {features.map((feature, index) => (
            <div className="flex gap-5 items-center" key={index}>
              <div className="w-[68px] h-[68px] bg-white rounded-full flex justify-center items-center m-auto">
                <img
                  src={feature.icon.src}
                  width={feature.icon.width}
                  height={feature.icon.height}
                  alt={feature.icon.alt}
                  className={`w-[44px] h-[44px] ${`${
                    feature?.shadow ? "drop-shadow-lg" : ""
                  } `} m-auto`}
                />
              </div>
              {feature.arrow && (
                <img
                  src={feature.arrow.src}
                  width={feature.arrow.width}
                  height={feature.arrow.height}
                  alt={feature.arrow.alt}
                  className={feature.arrow.class}
                />
              )}
            </div>
          ))} */}
            </div>
            <div className="grid sm:grid-cols-4 grid-cols-2 gap-4">
              {features.map((feature, index) => (
                <div className="text-center" key={index}>
                  <div className="relative">
                    <div className="sm:w-[68px] sm:h-[68px] w-[37px] h-[37px] bg-white rounded-full flex justify-center items-center m-auto">
                      <img
                        src={feature.icon.src}
                        width={feature.icon.width}
                        height={feature.icon.height}
                        alt={feature.icon.alt}
                        className={`sm:w-[44px] sm:h-[44px] w-[24px] h-[24px] ${`${
                          feature?.shadow ? "drop-shadow-lg" : ""
                        } `} m-auto`}
                      />
                    </div>
                    {/* set arrow with giving position */}
                    {feature.arrow && (
                      <img
                        src={feature.arrow.src}
                        width={feature.arrow.width}
                        height={feature.arrow.height}
                        alt={feature.arrow.alt}
                        className={feature.arrow.class}
                      />
                    )}
                  </div>

                  <h2 className="sm:text-[18px] text-[10px] sm:leading-[28px] font-semibold sm:mt-[24px] mt-[12px] sm:mb-3 mb-[6px]">
                    {feature.title}
                  </h2>
                  <p className="sm:text-[14px] text-[8px] text-[#E7E9EC] font-thin">
                    {feature.description}
                  </p>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="pb-[36px] pt-[12px]">
            {talents.map((talent, index) => (
              <div key={index}>
                <div key={index} className="text-center pt-[24px]">
                  <h2 className="text-pink text-[12px] font-semibold">
                    {talent.title}
                  </h2>
                  <p className="text-[20px] font-bold leading-[16px] mt-2">
                    {talent.description}
                  </p>
                </div>

                <div className="grid grid-cols-2 mt-3 gap-[10px]">
                  {talent.details.map((detail, index) => (
                    <div
                      key={index}
                      className="flex items-center text-start gap-[11px] text-[12px] font-semibold p-[6px]"
                    >
                      <div className="bg-white p-[5px] rounded-full">
                        <img
                          src={`/static/images/talents/${detail.icon}`}
                          width={16}
                          height={16}
                          alt={detail.icon}
                        />
                      </div>
                      <p className="text-[12px] font-semibold">{detail.text}</p>
                    </div>
                  ))}
                </div>

                {index === 0 && (
                  <div className="border-t-[1px] border-dashed border-[#E7E9EC] mt-[24px]"></div>
                )}
              </div>
            ))}
            {/* <div className="text-center">
              <h2 className="text-pink text-[12px] font-semibold">
                Got talent?
              </h2>
              <p className="text-[20px] font-bold leading-[16px] mt-2">
                Why job seekers love us
              </p>
            </div>

            <div className="grid grid-cols-2 mt-3">
              <div className="flex items-center gap-[11px]">
                <div className="bg-white p-[5px] rounded-full">
                  <img
                    src={"/static/images/talents/briefcase.png"}
                    width={16}
                    height={16}
                    alt="brief case"
                  />
                </div>
                <p className="text-[12px] font-semibold">
                  Ai-tailored job matches
                </p>
              </div>
            </div> */}
          </div>
        )}
      </section>

      {!isMobileDevice && (
        <>
          <DiscoverAssess />
          <BridgingTalentJobs />
        </>
      )}

      {/* unlock perfect */}
      {/* 
      {width > 512 && (
        <section className="sm:my-[60px] my-[24px] unlock-perfect-fit">
          <div className="flex sm:gap-[37px] gap-[11px] flex-wrap md:max-w-[1120px] m-auto justify-center">
            <div className="space-y-6 md:max-w-[313px] max-w-[93px]">
              <div>
                <img
                  src={"/static/images/dotted-linear-top-box.png"}
                  width={313}
                  height={156}
                  alt="dotted-linear-box"
                />
              </div>

              <Faqs
                title={"How do I find the right role?"}
                description={
                  "Our AI-powered tools match you to jobs based on your roles—not just keywords—connecting you with roles where you can truly thrive."
                }
                isPadding={true}
              />

              <div>
                <img
                  src={"/static/images/dotted-linear-bottom-box.png"}
                  width={313}
                  height={156}
                  alt="dotted-linear-box"
                />
              </div>
            </div>

            <div className="space-y-5 md:max-w-[372px] max-w-[126px]">
              <Faqs
                title={"How do I know if a job is right for me?"}
                description={
                  "Our role-specific scorecards highlight top matches, reducing your screen time by 60% and lowering hiring costs by 30%."
                }
              />

              <div className="bg-purple sm:p-6 p-[5px] rounded-[8px] text-white">
                <div
                  className="sm:py-[43px] py-[12px] text-center bg-no-repeat h-[54px] sm:h-[153px] content-center"
                  style={{
                    backgroundImage: `url('/static/images/border-dashed-frame.png')`,
                    backgroundSize: "contain",
                    width: "100%",
                  }}
                >
                  <h1 className="text-[7px] sm:text-[26px] font-bold">
                    Unlock Perfect Fit with
                  </h1>
                  <img
                    src={"/static/images/text-logo.png"}
                    width={132}
                    height={27}
                    alt="headsin text-logo"
                    className="m-auto sm:w-[132px] sm:h-[27px] w-[39px] h-[8px]"
                  />
                </div>
              </div>

              <Faqs
                title={"How can I keep candidate communications private?"}
                description={
                  "With secure, in-app messaging, you can connect confidently while keeping personal information protected."
                }
              />
            </div>

            <div className="space-y-6 md:max-w-[313px] max-w-[93px]">
              <div>
                <img
                  src={"/static/images/dotted-linear-top-box.png"}
                  width={313}
                  height={156}
                  alt="dotted-linear-box"
                />
              </div>

              <Faqs
                title={"How do I track my applications?"}
                description={
                  "With Headsin’s two-way traceability, you’re updated in real time on each step, helping you make informed decisions 4x faster."
                }
                isPadding={true}
              />

              <div>
                <img
                  src={"/static/images/dotted-linear-bottom-box.png"}
                  width={313}
                  height={156}
                  alt="dotted-linear-box"
                />
              </div>
            </div>
          </div>
        </section>
      )} */}

      {width < 512 && <HeadsinJourney />}

      {/* Boost section  */}
      <section
        className="linear-gradient-bg sm:py-[71px] py-[32px] px-5 sm:px-[260px] text-center mx-[20px] sm:mx-[60px] rounded-[40px]"
        id="join-list"
      >
        <div className="w-[60px] h-[60px] sm:w-[120px] sm:h-[120px] bg-white flex justify-center items-center rounded-full m-auto">
          <img
            src={"/static/images/logo.gif"}
            width={60}
            height={60}
            alt="rocket"
            className="m-auto w-[30px] h-[30px] sm:w-[60px] sm:h-[60px]"
          />
        </div>

        <h1 className="text-pink sm:text-[40px] text-[18px] font-bold sm:leading-[40px] sm:mt-[31px] mt-3">
          We’re Changing the Game
        </h1>
        <h2 className="sm:text-[48px] text-[22px] font-extrabold sm:leading-[48px] sm:mt-[11px] sm:mb-[31px] mb-3 text-[#2C2C2C]">
          Be the First to Experience It
        </h2>
        <form onSubmit={handleSubmit} className="space-y-2">
          <input
            type="email"
            required
            name="email"
            className="bg-white sm:py-[8px] py-[6px] px-4 w-[255px] rounded-tl-md rounded-bl-md text-black"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ border: "1px solid #DBDADE" }}
          />
          <Button
            type="submit"
            className="bg-purple text-white sm:py-[9px] py-[6px] px-[20px] sm:rounded-tr-md sm:rounded-br-md rounded-md"
          >
            Join waitlist
          </Button>
        </form>
      </section>

      {/* Join our team */}
      <section className="sm:my-[60px] my-[28px] max-w-[1200px] m-auto">
        <h1 className="font-mulish text-[28px] font-extrabold text-purple text-center block sm:hidden mb-[37px]">
          Join our Team
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-[63px] gap-[32px] px-5 sm:px-0">
          <img
            src={"/static/images/team.svg"}
            width={600}
            height={243}
            alt="team"
            className="mx-auto"
          />

          <div className="sm:space-y-6 space-y-4">
            <h1 className="text-purple font-normal text-[40px] hidden sm:block leading-[52px]">
              Join our <span className="font-bold">Team</span>{" "}
            </h1>
            <p className="text-start text-[#5C5C60] font-semibold sm:text-[18px] text-[14px]">
              Be the tech force revolutionizing recruitment and Job Marketplace.
            </p>
            <p className="text-start text-[#5C5C60] sm:text-[18px] font-semibold text-[14px]">
              join us to shape the future of hiring with groundbreaking AI
              innovations!
            </p>
            <p className="text-start text-[#5C5C60] sm:text-[18px] text-[14px] font-semibold">
              Please write us at
              <Link
                className="text-purple font-extrabold"
                to={"mailto:headsin.get@gmail.com"}
              >
                {" "}
                headsin.get@gmail.com{" "}
              </Link>
              or reach out us on LinkedIn
              <Link
                to="https://www.linkedin.com/company/headsinco/"
                target="_blank"
                className="text-purple font-extrabold"
              >
                {" "}
                {" headsin.co"}{" "}
              </Link>{" "}
            </p>
          </div>
        </div>
      </section>

      <Toast
        open={isFailedWaitList}
        setOpen={setIsFailedWaitList}
        message={message}
      />

      {isOpenWaitListModal && (
        <WaitListModal
          isOpen={isOpenWaitListModal}
          setIsOpen={setIsOpenWaitListModal}
          waitListModalSubmit={waitListModalSubmit}
        />
      )}

      {isSuccessWaitList && (
        <SuccessWaitListModal
          isOpen={isSuccessWaitList}
          setIsOpen={setIsSuccessWaitList}
        />
      )}
      <Footer />
    </div>
  );
};

export default Home;
