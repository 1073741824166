import React, { useEffect, useState } from "react";
import useIntersectionObserver from "../hooks/useIntersectionObserver";

const withLazyLoad = (WrappedComponent, threshold = 1) => {
  return (props) => {
    const [isInView, ref] = useIntersectionObserver(threshold);
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
      if (isInView && !hasLoaded) {
        setHasLoaded(true);
      }
    }, [isInView, hasLoaded]);

    return (
      <div ref={ref}>
        {hasLoaded ? <WrappedComponent {...props} /> : <div></div>}
      </div>
    );
  };
};

export default withLazyLoad;
