import React, { useEffect, useState } from "react";
import Button from "../ui/Button";
import Modal from "../ui/Modal";
import Loader from "../ui/Loader";

const WaitListModal = ({ isOpen, setIsOpen, waitListModalSubmit }) => {
  const [selected, setSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const options = [
    {
      id: "job-seeker",
      label: "Job Seeker",
      imageSrc:
        selected === "job-seeker"
          ? "job-seeker-active.png"
          : "job-seeker-icon.png",
    },
    {
      id: "employer",
      label: "Employer",
      imageSrc:
        selected === "employer" ? "employer-active.png" : "employer-icon.png",
    },
  ];

  useEffect(() => {
    return () => {
      setSelected("");
    };
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <h1 className="sm:text-[24px] text-[20px] font-bold text-[#4B465C] text-center">
            What describes you best
          </h1>

          <div className="grid grid-cols-2 mt-4 gap-4">
            {options.map((option, i) => (
              <label
                key={i}
                htmlFor={option.id}
                className="cursor-pointer text-center flex justify-center items-center"
                onClick={() => setSelected(option.id)}
              >
                <div
                  className={`border border-[#DBDADE] rounded-md w-[200px] h-[135px] text-[#4B465C] content-center ${
                    selected === option.id
                      ? "!border-purple border bg-[#f1ecf2] !text-purple"
                      : ""
                  }`}
                >
                  <img
                    src={`/static/images/${option.imageSrc}`}
                    width={35}
                    height={40}
                    alt={option.label}
                    className={`m-auto ${selected === option.id ? "" : ""}`}
                  />
                  <h2 className="font-bold text-[15px] my-2">{option.label}</h2>
                  <input
                    type="radio"
                    id={option.id}
                    name="job-seeker"
                    className="accent-purple"
                    checked={selected === option.id} // Check if selected
                    onChange={() => setSelected(option.id)} // Update selected value
                  />
                </div>
              </label>
            ))}
          </div>
          <div className="text-end mt-4">
            <Button
              onClick={async () => {
                setIsLoading(true);
                await waitListModalSubmit(selected);
                setIsLoading(false);
              }}
              isDisable={!selected}
              className={
                "bg-purple text-white px-5 py-[10px] rounded-md border hover:border-purple hover:text-purple hover:bg-transparent"
              }
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default WaitListModal;
